<template>
  <div class="score-sum-view">
    <tojoy-title-content title="得分汇总">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
        </div>
      </template>
    </tojoy-title-content>
    <div class="organize" v-show="tabName === 'myScore'">
      <tojoy-pagination-list
        :total="total"
        :size="size"
        @page-change="val => handleChangePage(val, 'myScore')"
        :loading="loading"
        :data="receivedData"
      >
        <template #item="{data}">
          <tojoy-card :data="data" schema="people">
            <template #downcard>
              <score-item-card :data="data" />
            </template>
          </tojoy-card>
        </template>
      </tojoy-pagination-list>
    </div>
    <div class="organize" v-show="tabName === 'organize'">
      <div class="select">
        <el-select v-model="value" class="use-sel" placeholder="请选择" @change="handleOrg">
          <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <tojoy-pagination-list
        :data="commentData"
        :total="total"
        :size="organizeSize"
        @page-change="val => handleChangePage(val, 'organize')"
        :loading="loading"
        :class="tabName === 'organize' ? 'pagination' : ''"
      >
        <template #item="{data}">
          <tojoy-card :data="data" schema="people">
            <template #downcard>
              <appraisal-item-card :data="data" />
            </template>
          </tojoy-card>
        </template>
      </tojoy-pagination-list>
    </div>
  </div>
</template>

<script>
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
import { sumTabNames } from '@/components/organizational/state.js'
import scoreItemCard from '@/components/organizational/score-item-card.vue'
import appraisalItemCard from '@/components/organizational/appraisal-item-card'
import TojoyTabs from '@/components/business/tabs'
import { mySum, evalOrgList, organizationList, getLevelList, queryId } from '@/api/api_organization'
export default {
  name: 'score-sum',
  components: {
    TojoyPaginationList,
    TojoyCard,
    TojoyTitleContent,
    scoreItemCard,
    appraisalItemCard,
    TojoyTabs
  },
  data() {
    return {
      ids: [],
      page: 1,
      size: 10,
      total: 0,
      organizeSize: 20,
      receivedData: [],
      commentData: [],
      tabs: sumTabNames,
      tabName: sumTabNames[0].name,
      value: 0,
      options: [],
      loading: false
    }
  },
  mounted() {
    this.getOrganizationList()
    this.loading = true
    this.getSumList()
  },
  methods: {
    getLevel() {
      getLevelList().then(res => {
        let arr = res.data
        localStorage.setItem('levelList', arr)
      })
    },
    /**
     * 我的得分
     */
    getSumList() {
      let param = {
        page: this.page,
        size: this.size
      }
      mySum(param)
        .then(res => {
          if (res.data) {
            let { list, total } = res.data
            this.receivedData = list
            this.total = total
          }
          this.loading = false
        })
        .catch(err => err)
    },
    getOrgId() {
      queryId()
        .then(res => {
          let { parentIdList } = res.data
          this.ids = parentIdList || []
        })
        .catch(err => {})
    },
    /**
     * 参评组织列表
     */
    getOrganizationList() {
      organizationList()
        .then(res => {
          this.options = res.data ? res.data : []
          this.value = this.options[0] && this.options[0].id
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**
     * 根据所选考评组织查询组织评比列表
     */
    handleOrg() {
      this.loading = true
      this.page = 1
      this.total = 0
      this.commentData = []
      this.getEvalOrgList()
    },
    /**
     * 组织绩效列表
     */
    getEvalOrgList() {
      let params = {
        eocId: this.value,
        page: this.page,
        size: this.organizeSize
      }
      evalOrgList(params)
        .then(res => {
          let { list, total } = res.data
          this.commentData = list
          this.total = total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleChangePage(val, type) {
      this.page = val
      this.loading = true
      if (type === 'myScore') {
        this.getSumList()
      } else {
        this.getEvalOrgList()
      }
    },
    handleTabClick({ index }) {
      this.commentData = []
      this.receivedData = []
      this.loading = true
      this.page = 1
      this.total = 0
      if (index === '1') {
        if (this.value) {
          this.getEvalOrgList()
        }
      } else {
        this.getSumList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.score-sum-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .select {
    width: auto;
    background: $white;
    padding: 16px 0 16px 15px;
    border-bottom: 1px solid #eeeeee;
    margin: 20px 30px -20px 30px;
    .use-sel {
      height: 32px;
      width: 27%;
    }
    /deep/.el-input__inner {
      height: 32px;
      line-height: 32px;
      color: $lightblack;
    }
    /deep/.el-input__icon {
      line-height: 32px;
    }
  }
  .tojoy-pagination-list {
    flex: 1;
  }
  .pagination {
    margin: 0;
    /deep/.item-card {
      padding-top: 0;
    }
  }
  .organize {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }
  /deep/.errorPage {
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    width: auto;
  }
  /deep/.item-card {
    margin-right: 0;
    padding-right: 0;
  }
}
</style>
