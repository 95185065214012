<template>
  <div class="appraisal-content">
    <div class="content-select"></div>
    <div class="content-left">
      <div class="content-left__wrap">
        <span class="content-left__time">{{ data.k }}</span>
        <i class="content-left__unit">年</i>
      </div>
    </div>
    <div class="content-right">
      <div class="score" v-for="(item, index2) in data.v" :key="index2">
        <div class="month">{{ item.k }}月</div>
        <div class="score-list" v-for="(it, index) in item.v" :key="index">
          <div class="list-left">
            {{ it.pocTitle }}
          </div>
          <div class="list-right" :class="it.pfLevel">{{ it.pfLevel }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appraisal-item-card',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.appraisal-content {
  display: flex;

  .content-left {
    margin-top: 15px;
    &__wrap {
      flex: none;
      width: 46px;
      height: 46px;
      background: $white;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
      border-radius: 3px;
      border: 1px solid $linecolor;
      text-align: center;
      line-height: 10px;
      padding-top: 12px;
      display: flex;
      flex-direction: column;
    }
    &__time {
      font-size: 15px;
      font-weight: 600;
      color: $lightblack;
      line-height: 10px;
    }
    &__unit {
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 22px;
      transform: scale(0.8);
    }
  }
  .content-right {
    flex: 1;
    .score {
      margin-top: 20px;
      .month {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $lightblack;
        line-height: 18px;
        margin-left: 15px;
      }
      .score-list {
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 0px 0px #eeeeee;
        margin-left: 15px;
        padding-top: 18px;
        padding-bottom: 18px;
        .list-left {
          flex: 1;
          font-size: 14px;

          font-weight: 400;
          color: $darkgrey;
          line-height: 14px;
        }
        .list-right {
          text-align: right;
          flex: 1;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: $C89557;
          line-height: 14px;
          margin-right: 15px;
        }
        .A {
          color: $blue;
        }
        .C {
          color: $red;
        }
        .B {
          color: $darkgrey;
        }
      }
    }
  }
}
</style>
