<template>
  <div class="score">
    <div class="year">{{ data.k }}年</div>
    <div class="score-list" v-for="(item, index) in data.v" :key="index">
      <div class="list-left">
        <span class="month">{{ item.month }}月</span>
        <span class="text" v-if="item.pocTitle"
          >{{ item.pocTitle }}{{ item.orgPfLevel ? ':' : '' }}</span
        >
        <span class="level" v-if="item.orgPfLevel"
          >绩效<span :class="item.orgPfLevel">{{ item.orgPfLevel }}</span></span
        >
      </div>
      <div class="list-right" v-if="item.orgpf">
        <span class="number" :class="item.orgpf < 0 ? 'red' : item.orgpf === 0 ? 'grey' : ''">{{
          item.orgpf | formateNumber
        }}</span>
        <span class="text" :class="item.orgpf < 0 ? 'red' : item.orgpf === 0 ? 'grey' : ''"
          >分</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'score-item-card',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  filters: {
    formateNumber(val = 0) {
      if (parseFloat(val) >= 0) {
        return `+${val}`
      } else {
        return `${val}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.score {
  .year {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: $lightblack;
    line-height: 20px;
  }
  .score-list {
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 0px 0px $linecolor;
    margin-left: 10px;
    padding-top: 21px;
    padding-bottom: 21px;
    .list-left {
      flex: 1;
      display: flex;
      align-items: center;
      .month {
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $lightblack;
        line-height: 18px;
        // margin-right: 10px;
        width: 47px;
      }
      .text,
      .level {
        font-size: 12px;

        font-weight: 400;
        color: $darkgrey;
        line-height: 12px;
      }
      .C {
        color: $red;
      }
      .S {
        color: $C89557;
      }
      .A {
        color: $blue;
      }
      .text {
        margin-right: 7px;
      }
    }
    .list-right {
      flex: 1;
      text-align: right;
      padding-right: 15px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $blue;
      line-height: 14px;
      .text {
        font-size: 12px;
        font-weight: 400;
        color: $blue;
        line-height: 12px;
      }
      .grey {
        color: $grey;
      }
      .red {
        color: $red;
      }
    }
  }
}
</style>
